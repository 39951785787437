import {t} from '@lingui/macro'
import {Breadcrumbs as MUIBreadcrumbs, Paper, Typography, useMediaQuery} from '@mui/material'
import Head from 'next/head'
import {useRouter} from 'next/router'
import React, {FC} from 'react'
import {UrlObject} from 'url'

import {TextLink} from 'core/components/text-link'
import * as settings from 'settings'

interface BreadcrumbsProps {
    path: Array<{
        href?: string | UrlObject
        as?: string
        label?: string
    }>
}

export const Breadcrumbs: FC<React.PropsWithChildren<BreadcrumbsProps>> = ({path}) => {
    const print = useMediaQuery('print')
    const router = useRouter()

    if (print) return null

    const handleClick = (href: string | UrlObject) => {
        if (
            typeof href === 'string' &&
            (href.includes('supply-orders') || href.includes('quotes') || href.includes('sales'))
        ) {
            const {id: _id, ...queryWithoutId} = router.query

            let storageKey = ''
            if (href.includes('supply-orders')) {
                storageKey = 'supply-orders-filters'
            } else if (href.includes('quotes')) {
                storageKey = 'quotes-filters'
            } else if (href.includes('sales')) {
                storageKey = 'sales-filters'
            }

            const savedFilters = sessionStorage.getItem(storageKey)
            const filters = savedFilters ? JSON.parse(savedFilters) : {}

            router.push({
                pathname: href,
                query: Object.keys(queryWithoutId).length > 0 ? queryWithoutId : filters,
            })
            return
        }
        router.push(href)
    }

    return (
        <Paper
            elevation={0}
            sx={{'py': 1, 'px': 2, '@media print': {display: 'none'}}}>
            <Head>
                <title>
                    {path
                        .map(({label}) => label)
                        .reverse()
                        .concat([settings.SITE_NAME])
                        .join(' / ')}
                </title>
            </Head>
            <MUIBreadcrumbs>
                <TextLink
                    href='/'
                    color='inherit'
                    onClick={e => {
                        e.preventDefault()
                        router.push('/')
                    }}>
                    {t`Home`}
                </TextLink>
                {path.map(({href, as, label}) => {
                    if (href)
                        return (
                            <TextLink
                                key={label}
                                href={href}
                                as={as}
                                onClick={e => {
                                    e.preventDefault()
                                    handleClick(href)
                                }}>
                                {label || '-'}
                            </TextLink>
                        )
                    return (
                        <Typography
                            key={label}
                            color='textPrimary'>
                            {label || '-'}
                        </Typography>
                    )
                })}
            </MUIBreadcrumbs>
        </Paper>
    )
}
